import { useNavigate } from "react-router-dom"


export const useTestResultModalState = () => {

  const navigate = useNavigate();

  const onFinish = () => {
    navigate('/test-list')
  }

  return {
    onFinish,
  } 
}