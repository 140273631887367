import { RouteObject } from "react-router-dom";
import { SignInPage } from "../SignIn";
import { Test } from "../Test";
import { TestList } from "../TestList";


export const routes: RouteObject[] = [
  {
    path: '/',
    element: <SignInPage />
  },
  {
    path: '/test-list',
    element: <TestList />
  },
  {
    path: '/test/:id',
    element: <Test />
  }
];

