import './styles/app.css';
import reactDom from 'react-dom';
import { App } from './pages/App';
import 'antd/dist/antd.min.css'

reactDom.render(<App />, document.getElementById('root'));


document.addEventListener("copy", (event: ClipboardEvent) => {
  event.clipboardData?.setData("text/plain", `Копирование теста не допускается`);
  event.clipboardData?.setData("text/html", `Копирование теста не допускается`);
  event.preventDefault();
});