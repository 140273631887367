import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
`;

export const Card = styled.div`
  max-width: 60%;
  max-height: 80%;
  min-height: 50%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  border-radius: .25rem;
  box-shadow: 2px 3px 12px 0px lightgrey;
  padding: 1rem 2rem 2rem 2rem;
  gap: 2rem;
`;

export const PageLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`