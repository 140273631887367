import { useParams, useNavigate } from 'react-router-dom';
import useServiceExecute from '../../common/hooks/use-service-execute';
import { TestService } from "../../transport/services/test.service";
import { message } from 'antd';
import { useEffect, useState, useCallback } from 'react';
import { Question } from '../../common/interfaces/question.interface';

export const useTestState = () => {
  const { id } = useParams<{id: string}>();
  const {onRequest, data: test} = useServiceExecute({
    service: TestService.findOne,
  });
  useEffect(() => {
    if (id) onRequest(id);
  }, [id, onRequest]);
  
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [currentQuestion, setCurrentQuestion] = useState<Question | null>(null);
  const [isFirstQuestion, setIsFirstQuestion] = useState<boolean>(true);
  const [isLastQuestion, setIsLastQuestion] = useState<boolean>(false);
  const [currentAnswers, setCurrentAnswers] = useState<{questionId: number, answerIds: number[]}[]>([]);

  const setNextQuestion = useCallback(() => {
    if (isLastQuestion || !test) return;
    const index = currentIndex + 1;
    setCurrentIndex(index);
    setCurrentQuestion(test.questions[index]);
  }, [isLastQuestion, test, currentIndex]);
  
  const setPreviousQuestion = useCallback(() => {
    if (isFirstQuestion || !test) return;
    const index = currentIndex - 1;
    setCurrentIndex(index);
    setCurrentQuestion(test.questions[index]);
  }, [currentIndex, isFirstQuestion, test])
  
  useEffect(() => {
    if(!test) return;
    setCurrentQuestion(test.questions[currentIndex])
    if (currentIndex === 0) {
      setIsFirstQuestion(true);
      setIsLastQuestion(false);
      return;
    }
    if(currentIndex > 0 && currentIndex < test.questions.length - 1) {
      setIsFirstQuestion(false);
      setIsLastQuestion(false);
      return;
    }
    if(currentIndex === test.questions.length - 1) {
      setIsFirstQuestion(false);
      setIsLastQuestion(true);
    }
  }, [currentIndex, test]);
  
  const setQuestionAnswer = useCallback((questionId: number, answerIds: number[]) => {
    if(!test) return;
    const question = test.questions.find(item => item.id === questionId);
    if(!question) return;
    const answers = [...currentAnswers];
    const alreadyFilled = answers.find(item => item.questionId === questionId);
    if(alreadyFilled) {
      alreadyFilled.answerIds = answerIds;
    } else {
      answers.push({questionId, answerIds});
    }
    setCurrentAnswers(answers);
  }, [currentAnswers, test])

  const getQuestionAnswer = useCallback((questionId: number) => {
    return currentAnswers.find(item => item.questionId === questionId)?.answerIds || [];
  },[currentAnswers])

  return {
    test,
    currentIndex,
    setCurrentIndex,
    currentQuestion,
    isFirstQuestion,
    isLastQuestion,
    setNextQuestion,
    setPreviousQuestion,
    getQuestionAnswer,
    setQuestionAnswer,
    currentAnswers,
  }
}