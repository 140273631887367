import axios, { AxiosRequestConfig } from "axios";

//'http://176.122.75.171:3005',
export const httpTransport = axios.create({
  baseURL: 'http://176.122.75.171:3005',
});

httpTransport.interceptors.response.use((value) => value, async (error) => {
  let errorText = 'Произошла неизвестная ошибка';
  if (!error.response) {
    errorText = 'Произошла сетевая ошибка';
  }
  console.log(errorText);
  throw error;
});

httpTransport.interceptors.request.use((config: AxiosRequestConfig) => {
  const accessToken = localStorage.getItem('accessToken');
  if (config.headers && accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
})
