import { FC } from 'react';
import { Button, Form, Input } from 'antd';
import { useSignIn } from './use-sign-in';
import { Card, Header, PageLayout } from '../../common/styled/app.styled';

export const SignInPage: FC = () => {
  const {initialValues, onSubmit} = useSignIn();

  return (
    <PageLayout>
      <Card>
        <Header>
          Профвектор: Система тестирования
        </Header>
        <Form
          style={{width: '100%'}}
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          initialValues={initialValues}
          onFinish={onSubmit}
          autoComplete="off"
        >
          <Form.Item label="Логин" name="login" rules={[{ required: true, message: 'Необходимо ввести логин' }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Пароль" name="password" rules={[{ required: true, message: 'Необходимо ввести пароль' }]} >
            <Input.Password />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Вход
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </PageLayout>
  )
}