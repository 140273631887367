import { message } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useServiceExecute from "../../common/hooks/use-service-execute"
import { UserService } from '../../transport/services/user.service';

export const useSignIn = () => {

  const navigate = useNavigate();

  const {data, onRequest} = useServiceExecute({
    service: UserService.signIn,
    onError: () => message.error('Произошла ошибка при входе. Повторите позже.'),
  });


  useEffect(() => {
    if (data) {
      const { accessToken } = data;
      localStorage.setItem('accessToken', accessToken);
      message.success('Вход успешен!');
      navigate('/test-list');
    };
  }, [data, navigate]);

  useEffect(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('currentUser');
  }, [])

  const onSubmit = (payload: {login: string, password: string}) => {
    onRequest(payload);
  }

  const initialValues = {
    login: '',
    password: '',
  }

  return {
    initialValues,
    onSubmit,
  }
}
