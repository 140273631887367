import useServiceExecute from "../../common/hooks/use-service-execute"
import { TestWithQuestions } from "../../common/interfaces/test.interface"
import { TestService } from "../../transport/services/test.service"
import { message } from 'antd';
import { useCallback } from "react"

export const useSubmitResult = () => {
  const {data: testResult, onRequest} = useServiceExecute({
    service: TestService.submitTest
  })

  const onSubmit = useCallback((test: TestWithQuestions, answers: {questionId: number, answerIds: number[]}[]) => {
    if(
      test.questions.length !== answers.length || 
      answers.find(a =>  a.answerIds.length === 0) !== undefined
    ) {
      message.error('Необходимо выбрать ответы во всех вопросах');
      return;
    }
    const isTestAttempt: boolean = Boolean(JSON.parse(localStorage.getItem('testAttempt') || "true"));
    onRequest({
      testId: test.id,
      answers,
      isTestAttempt,
    });
  }, [onRequest])

  return {
    testResult,
    onSubmit,
  }
}