import { TestResult } from "../../common/interfaces/test-result.interface";
import { Test, TestWithQuestions } from "../../common/interfaces/test.interface";
import { httpTransport } from '../index';

interface SubmitTest {
  testId: number,
  answers: {
    questionId: number,
    answerIds:  number[],
  }[],
  isTestAttempt: boolean;
}

export class TestService {

  static async findAllTests (): Promise<Test[]> {
    const response = await httpTransport.get<Test[]>('/test');
    return response.data
  }

  static async findOne (id: number | string) {
    const response = await httpTransport.get<TestWithQuestions>(`/test/${id}`);
    return response.data;
  }

  static async submitTest(data: SubmitTest) {
    const response = await httpTransport.post<TestResult>('/test/submit', data);
    return response.data;
  }
}