import { Spin } from "antd";
import { FC } from 'react';
import { Card, Header,  PageLayout } from "../../common/styled/app.styled";
import { List } from "./styled";
import { TestItem } from "./TestItem";
import { useTestList } from './use-test-list';
import { useUser } from '../../common/hooks/use-user';

export const TestList: FC = () => {
  const {tests, isLoading, navigateToTest} = useTestList();

  const onItemClick = (id: number, testAttempt: boolean) => {
    localStorage.setItem('testAttempt', JSON.stringify(testAttempt));
    navigateToTest(id)
  }
  return (
    <PageLayout>
      <Card>
        <Header>Выберите один из доступных тестов:</Header>
        <List>
          {isLoading && <Spin size='large'/>}
          {tests && !tests.length && <div>Нет доступных тестов</div>}
          {tests?.map(((test, key) => <TestItem key={key} test={test} onClick={onItemClick}/>))}
        </List>
      </Card>
    </PageLayout>
  )
}
