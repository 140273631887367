import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useServiceExecute from "../../common/hooks/use-service-execute"
import { TestService } from "../../transport/services/test.service"

export const useTestList = () => {
  const {data: tests, loading: isLoading, onRequest} = useServiceExecute({
    service: TestService.findAllTests,
  });

  useEffect(() => {
    onRequest(undefined);
  }, [onRequest]);

  const navigate = useNavigate();

  return {
    tests,
    isLoading,
    navigateToTest: (id: number | string) => navigate(`/test/${id}`),
  };
}