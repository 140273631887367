import { Button, Card } from "antd";
import Modal from "antd/lib/modal/Modal";
import { FC } from "react";
import { TestResult } from "../../../common/interfaces/test-result.interface";
import { TestResultQuestions } from "./TestResultQuestions";
import { useTestResultModalState } from "./use-test-result-modal-state";

interface TestResultModalProps {
  data?: TestResult,
}
export const TestResultModal: FC<TestResultModalProps> = ({data}) => {

  const { onFinish } = useTestResultModalState();

  return (
    <Modal
      width={'80%'}
      style={{
        top: '20px',
      }}
      onCancel={onFinish}
      title={'Результаты теста'}
      visible={Boolean(data)}
      footer={[
        <Button onClick={onFinish}>Вернуться к списку тестов</Button>
      ]}
    >
      {data?.student.last_name && <p><strong>Фамилия:</strong>{' '}{data?.student.last_name}</p>}
      {data?.student.first_name && <p><strong>Имя:</strong>{' '}{data?.student.first_name}</p>}
      {data?.student.middle_name && <p><strong>Отчество:</strong>{' '}{data?.student.middle_name}</p>}
      <p><strong>Правильные ответы:</strong>{' '}{`${data?.succeedQuestions}/${data?.totalQuestions}`}({data?.result})</p>
      {
        data?.questions && <TestResultQuestions questions={data.questions} />
      }
    </Modal>
  )
}
