import { Button } from "antd";
import { FC, useCallback } from "react";
import { Test } from "../../../common/interfaces/test.interface";
import { ButtonContainer, ListItem } from "./styled";

interface TestItemProps {
  test: Test,
  onClick: (id: number, testAttempt: boolean) => void,
}
export const TestItem: FC<TestItemProps> = ({ test, onClick }) => {
  const {id, name} = test;

  const onItemClick = useCallback((testAttempt: boolean) => {
    onClick(id, testAttempt);
  }, [id, onClick]);

  return (
    <ListItem>
      {name}
      <ButtonContainer>
        <Button type="dashed" onClick={onItemClick.bind(null, true)}>
          Тестовая попытка
        </Button>
        <Button type="primary" onClick={onItemClick.bind(null, false)}>
          Пройти тест
        </Button>
      </ButtonContainer>
    </ListItem>
  )
}