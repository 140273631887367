import { Card } from "antd";
import React, { FC, useState } from "react";

interface QuestionProps {
  content: string;
  answers: {
    content: string;
    isCorrect: boolean;
    isChoosed: boolean;
  }[];
}
const Question: FC<QuestionProps> = ({content, answers}) => {
  return (
    <div>
      <div dangerouslySetInnerHTML={{__html: content}} />
      <br/>
      <br/>
      <div><strong>Ответы на вопросы:</strong></div>
      {answers.map((answer, i) => {
        const {isCorrect, isChoosed} = answer;
        let color = 'black';
        if(isCorrect && isChoosed) color = '#99FF33';
        if(!isCorrect && isChoosed) color = '#FF6666';
        if(isCorrect && !isChoosed) color = '#99FF33';
        return (
          <div style={{display: 'flex', flexDirection: 'row'}}>
            {i + 1}.
            <div style={{ color }} dangerouslySetInnerHTML={{__html: answer.content}}/>
          </div>
        );
      })}
    </div>
  )
}

interface TestResultQuestionsProps {
  questions: QuestionProps[];
}
export const TestResultQuestions: FC<TestResultQuestionsProps> = ({questions}) => {
  
  const tabs = questions.map((e, i) => ({
    key: `question${i + 1}`,
    tab: `Вопрос №${i + 1}`,
  }));

  const [activeTab, setActiveTab] = useState<string>(tabs[0].key);

  const tabsContent = tabs.reduce((content, tab, index) => {
    content[tab.key] = <Question {...questions[index]}/>
    return content;
  }, {} as Record<string, React.ReactNode>);

  return (
    <Card
      title='Просмотр вопросов'
      tabList={tabs}
      activeTabKey={activeTab}
      onTabChange={(key) => setActiveTab(key)}
    >
      {tabsContent[activeTab]}
    </Card>
  )
}