import { FC } from "react";
import { BrowserRouter, useRoutes } from "react-router-dom"
import { routes } from "./routes"

const Routes = () => useRoutes(routes);


export const App: FC = () => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
}