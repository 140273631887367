import { httpTransport } from '../index';

interface SignIn {
  login: string,
  password: string,
}

export interface User {
  fullname: string;
  contract: {
    name: string;
  }
}

export class UserService {

  static async signIn (data: SignIn) {
    const response = await httpTransport.post<{accessToken: string}>('/test-user/auth', data);
    return response.data;
  }

  static async getMe (): Promise<User> {
    const response = await httpTransport.get<User>('/test-user/get-me');
    return response.data;
  }

}
