import { Button, Spin } from "antd";
import { PageLayout, Row } from "../../common/styled/app.styled"
import { TestDrawer } from "./TestDrawer";
import { useTestState } from "./use-test-state"


export const Test = () => {

  
  const {currentQuestion, ...other} = useTestState();

  return (
    <PageLayout>
      {currentQuestion ? <TestDrawer {...other} currentQuestion={currentQuestion}/> : <Spin />}
    </PageLayout>
  )
}