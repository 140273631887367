import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FC } from "react";
import { Card, Header } from "../../../../common/styled/app.styled";
import { AnswerList } from "../../question.styled";
import { QuestionProps } from "../question-props.interface";

export const SingleQuestion: FC<QuestionProps> = ({question, questionIndex, setAnswers, getAnswers }) => {

  const onChange = (answerId: number, e: CheckboxChangeEvent) => {
    setAnswers(question.id, e.target.checked ? [answerId] : []);
  }

  const choosedAnswers = getAnswers(question.id);
  return (
    <Card style={{ minWidth: '50%', maxWidth: '80%' }}>
      <Header>Вопрос №{questionIndex}</Header>
      <div dangerouslySetInnerHTML={{__html: question.content}} />
      <AnswerList>
      Варианты ответов:
      {
        question.answers.map((answer, i) =>
          <Checkbox
            key={i + 5}
            checked={(choosedAnswers || []).includes(answer.id)}
            onChange={onChange.bind(null, answer.id)}
          >
            <div dangerouslySetInnerHTML={{__html: answer.content}} />
          </Checkbox>
        )
      }
      </AnswerList>
    </Card>
  )
}
