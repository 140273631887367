import { Button } from "antd";
import { FC, useCallback, useMemo } from "react";
import { Question } from '../../common/interfaces/question.interface';
import { TestWithQuestions } from "../../common/interfaces/test.interface";
import { Row } from "../../common/styled/app.styled";
import { MultipleQuestion } from "./Question/MultipleQuestion";
import { QuestionProps } from "./Question/question-props.interface";
import { SingleQuestion } from "./Question/SingleQuestion";
import { QuestionPagginator } from './QuestionPaginator';
import { TestResultModal } from "./TestResult";
import { useSubmitResult } from './use-submit-result';

interface TestDrawerProps {
  test?: TestWithQuestions,
  currentIndex: number,
  setCurrentIndex: (index: number) => void,
  currentQuestion: Question,
  isFirstQuestion: boolean,
  isLastQuestion: boolean,
  setNextQuestion: () => void,
  setPreviousQuestion: () => void,
  getQuestionAnswer: (questionId: number) => number[],
  setQuestionAnswer: (questionId: number, answerIds: number[]) => void,
  currentAnswers: {questionId: number, answerIds: number[]}[],
}
export const TestDrawer: FC<TestDrawerProps> = ({
  currentIndex,
  currentQuestion: question,
  isFirstQuestion,
  isLastQuestion,
  setNextQuestion,
  setPreviousQuestion,
  getQuestionAnswer: getAnswers,
  setQuestionAnswer: setAnswers,
  currentAnswers,
  setCurrentIndex,
  test, 
}) => {

  const questionProps: QuestionProps = useMemo(() => ({
    question,
    questionIndex: currentIndex + 1,
    getAnswers,
    setAnswers,
  }), [getAnswers, question, currentIndex, setAnswers])

  const {onSubmit, testResult} = useSubmitResult();

  const submitTest = useCallback(() => {
    if (test) onSubmit(test, currentAnswers)
  }, [currentAnswers, onSubmit, test])

  return (
    <>
      {
        question.isMultiple ? 
          <MultipleQuestion {...questionProps}/> : 
          <SingleQuestion {...questionProps}/>
      }
      <br></br>
      <Row>
        {!isFirstQuestion && (<Button onClick={setPreviousQuestion}>Предыдущий вопрос</Button>)}
        {!isLastQuestion && (<Button onClick={setNextQuestion}>Следующий вопрос</Button>) }
        {isLastQuestion && (<Button onClick={submitTest}>Завершить тест</Button>)}
      </Row>
      <br></br>
      <QuestionPagginator test={test} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} getAnswers={getAnswers}/>
      <TestResultModal data={testResult}/>
    </>
  )
}