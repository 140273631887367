import { Button } from "antd";
import React, { FC, useMemo } from "react"
import styled from "styled-components";
import { TestWithQuestions } from "../../common/interfaces/test.interface"

const PaginatorContainer = styled.div`
  max-width: 60%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 2px;
`


interface QuestionPaginatorProps {
  test?: TestWithQuestions,
  currentIndex: number,
  setCurrentIndex: (index: number) => void,
  getAnswers: (questionId: number) => number[],
}
export const QuestionPagginator: FC<QuestionPaginatorProps> = ({test, setCurrentIndex, getAnswers}) => {
  const items = useMemo(() => (test?.questions || []).map(({id}, i) => {
    const answers = getAnswers(id);
    return (
      <Button
        key={i * 10 + 24}
        onClick={() => setCurrentIndex(i)}
        type="dashed"
        style={answers.length ? {background: '#1890FF', color: 'white'} : undefined}>
          {i + 1}
      </Button>
    );
  }), [getAnswers, test?.questions, setCurrentIndex]);

  return (
    <PaginatorContainer>
      {items}
    </PaginatorContainer>

  )
}
